import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import getRandomSubarray from "../utils/getRandomSubarray"
import ProjectBlock from "../components/projectBlock"

const NotFoundPage = ({ data, location }) => {
  const NUM = 4
  const randomProjects = getRandomSubarray(data.projects.edges, NUM)
  return (
    <Layout location={location}>
      <SEO></SEO>
      <p className="p-8 w-full text-center bg-yellow-300 mb-8">
        Page Not Found. Showing you <span className="font-black">{NUM}</span>{" "}
        random projects.
      </p>
      {randomProjects.map((e, i) => {
        const slug = e.node.slug
        const frontmatter = e.node.frontmatter
        return (
          <ProjectBlock
            slug={"../" + slug}
            frontmatter={frontmatter}
            i={i}
          ></ProjectBlock>
        )
      })}
    </Layout>
  )
}
export const pageQuery = graphql`
  {
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { visible: { eq: true } }
      }
      sort: {
        fields: [frontmatter___year, frontmatter___position]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          body
          slug
          frontmatter {
            title
            category
            displayedCategory
            publication
            social
            external
            github
            video
            collaborators
            contributors
            role
            displayed_time
            embeddedImages {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
            tags
            description

            year
            position
          }
        }
      }
    }
  }
`

export default NotFoundPage
